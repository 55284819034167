<template>
  <div class='maturity-factors'>
    <div class='zui'>6 Factors of Corporate Innovation</div>
    <p class='gku'>Find out your organization’s strengths and opportunity areas that can enhance your innovation advancement.</p>
    <maturity-factors-component class='gyn'
      mode='widget'
      :data='score'
      @select='changeFactor'/>
    <template v-if='factor === "leadership"'>
      <div class='osh'>Leadership</div>
      <p class='skc'>How well are the innovation strategy, vision, and roadmap planned and communicated?</p>
    </template>
    <template v-if='factor === "tools"'>
      <div class='osh'>Tools</div>
      <p class='skc'>How advanced and well-adopted are the technologies and tools?</p>
    </template>
    <template v-if='factor === "data"'>
      <div class='osh'>Data</div>
      <p class='skc'>How well are the data being collected, stored, and utilized to generate value?</p>
    </template>
    <template v-if='factor === "ecosystem"'>
      <div class='osh'>Ecosystem</div>
      <p class='skc'>How good is the reach and the quality of the ecosystem?</p>
    </template>
    <template v-if='factor === "organizational-process"'>
      <div class='osh'>Organizational Process</div>
      <p class='skc'>How well-defined are the decision-making process and criteria?</p>
    </template>
    <template v-if='factor === "team-capability"'>
      <div class='osh'>Team Capability</div>
      <p class='skc'>How good is the team structure as well as the innovation skills and culture?</p>
    </template>
  </div>
</template>

<script>
  import { ref } from 'vue'
  import MaturityFactors from '@/components/maturity-factors/maturity-factors.vue'

  export default {
    components: {
      'maturity-factors-component': MaturityFactors
    },

    setup () {
      let factor = ref('leadership')

      let randomScore = index => {
        let length = 6
        let score = Array.from({ length }, () => Math.random() * 5 + 5 << 0)
        score[index] = 15
        return score
      }

      let score = ref(randomScore(0))

      let changeFactor = newFactor => {
        switch (newFactor) {
          case 'leadership':
            factor.value = 'leadership'
            score.value = randomScore(0)
            break
          case 'tools':
            factor.value = 'tools'
            score.value = randomScore(1)
            break
          case 'data':
            factor.value = 'data'
            score.value = randomScore(2)
            break
          case 'ecosystem':
            factor.value = 'ecosystem'
            score.value = randomScore(3)
            break
          case 'organizational-process':
            factor.value = 'organizational-process'
            score.value = randomScore(4)
            break
          case 'team-capability':
            factor.value = 'team-capability'
            score.value = randomScore(5)
            break
        }
      }

      return {
        factor,
        score,
        changeFactor
      }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .zui {
    font-weight: map.get(variables.$font-weights, 'bold');
    font-size: map.get(variables.$font-sizes, 'lg');
    line-height: 1.5;
  }

  .gku {
    margin: 24px 0;
    line-height: 1.5;
  }

  .gyn {
    margin: 24px auto;
    max-width: 600px;
  }

  .osh {
    font-weight: map.get(variables.$font-weights, 'bold');
    line-height: 1.5;
  }

  .skc {
    margin: 8px 0 0;
    line-height: 1.5;
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .gku {
      margin: 32px 0;
    }

    .gyn {
      margin: 32px auto;
    }
  }
</style>
