<template>
  <div class='maturity-analysis'>
    <div class='ljm'>Maturity Analysis</div>
    <p class='gku'>After completing your assessment, you will receive the result of your level of corporate innovation maturity, innovation aptitude, and detailed analysis of organization’s capability. Here are how you can interpret your results and what we recommend for your way forward.</p>
    <maturity-stages-component class='dkf'/>
    <aptitude-types-component class='dkf'/>
    <maturity-factors-component class='dkf'/>
    <div class='juq'>
      <router-link :to='{ name: "test" }'>
        <button-element class='fwb'>Take the Assessment</button-element>
      </router-link>
    </div>
    <div class='kgt'
      :style='{ backgroundImage: "url(images/04b1.png)" }'>
    </div>
  </div>
</template>

<script>
  import AptitudeTypes from './aptitude-types.vue'
  import MaturityFactors from './maturity-factors.vue'
  import MaturityStages from './maturity-stages.vue'

  export default {
    components: {
      'aptitude-types-component': AptitudeTypes,
      'maturity-factors-component': MaturityFactors,
      'maturity-stages-component': MaturityStages
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .maturity-analysis {
    position: relative;
    z-index: 0;
  }

  .ljm {
    margin: 24px 0;
    font-weight: map.get(variables.$font-weights, 'bold');
    font-size: map.get(variables.$font-sizes, 'xl');
    line-height: 1.5;
  }

  .gku {
    margin: 24px 0;
    line-height: 1.5;
  }

  .dkf {
    margin-top: 24px;
    padding: 24px;
    background-color: white;
    border-radius: 4px;
  }

  .juq {
    display: flex;
    flex-direction: column;
    margin: 48px 0 24px;
  }

  .fwb {
    justify-content: center;
    width: 100%;
  }

  .kgt {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
  }

  @media (min-width: map.get(variables.$breakpoints, 'xs')) {
    .juq {
      flex-direction: row;
      justify-content: center;
    }
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .ljm {
      margin: 32px 0;
    }

    .gku {
      margin: 32px 0;
    }

    .dkf {
      margin-top: 32px;
      padding: 32px;
    }

    .juq {
      margin: 64px 0 32px;
    }
  }
</style>
