<template>
  <div class='click-hint'
    @click='hide'>
    <slot/>
    <template v-if='!hidden'>
      <div class='poi'
        :style='{ top, right, bottom, left }'>
      </div>
    </template>
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue'

  export default {
    props: {
      top: String,
      right: String,
      bottom: String,
      left: String,
      name: String
    },

    setup (props) {
      let hidden = ref(false)
      let storageItemKey = `click-hint/${props.name}`

      let hide = () => {
        hidden.value = true
        localStorage.setItem(storageItemKey, true)
      }

      onMounted(() => {
        hidden.value = localStorage.getItem(storageItemKey)
      })

      return { hidden, hide }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .click-hint {
    position: relative;
  }

  .poi {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 8px;
    pointer-events: none;

    &::after {
      position: absolute;
      top: -4px;
      left: -4px;
      border: 1px solid white;
      width: 24px;
      height: 24px;
      box-sizing: border-box;
      border-radius: 12px;
      animation: pulse 2s infinite;
      content: '';
    }
  }

  @keyframes pulse {
    0% {
      opacity: 0;
      transform: scale(0.95);
    }

    30% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      transform: scale(1.25);
    }
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .poi {
      width: 20px;
      height: 20px;
      border-radius: 10px;

      &::after {
        width: 28px;
        height: 28px;
        border-radius: 14px;
      }
    }
  }
</style>
