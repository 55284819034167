<template>
  <div class='maturity-stages'>
    <div class='zui'>5 Stages of Corporate Innovation Maturity</div>
    <p class='gku'>Maturity levels apply to an organization’s performance and progress on its corporate innovation journey, labeled Level 0 to Level 5 which achieve each stage. Each stage builds on the previous stages by adding new key actions that are able to move the company to the next level of corporate innovation.</p>
    <click-hint-component
      top='37%'
      left='34%'
      name='maturity-stages'>
      <maturity-stages-component class='gyn'
        mode='widget'
        :data='score'
        @select='changeStage'/>
    </click-hint-component>
    <template v-if='stage === "newcomer"'>
      <div class='osh'>Level 1 Newcomer</div>
      <p class='skc'>Being aware of the need to innovate but having a significant reluctance to change across the organization.</p>
    </template>
    <template v-if='stage === "explorer"'>
      <div class='osh'>Level 2 Explorer</div>
      <p class='skc'>Experimenting various innovation approaches but still having limited innovation experience and know-how.</p>
    </template>
    <template v-if='stage === "challenger"'>
      <div class='osh'>Level 3 Challenger</div>
      <p class='skc'>Establishing internal corporate innovation capability as well as processes and governance in the organization.</p>
    </template>
    <template v-if='stage === "practitioner"'>
      <div class='osh'>Level 4 Practitioner</div>
      <p class='skc'>Launching initial set of innovation-driven products and services.</p>
    </template>
    <template v-if='stage === "champion"'>
      <div class='osh'>Level 5 Champion</div>
      <p class='skc'>Consistently succeeding in generating substantial business results through disruptive innovation.</p>
    </template>
  </div>
</template>

<script>
  import { ref } from 'vue'
  import ClickHint from '@/components/click-hint/click-hint.vue'
  import MaturityStages from '@/components/maturity-stages/maturity-stages.vue'

  export default {
    components: {
      'click-hint-component': ClickHint,
      'maturity-stages-component': MaturityStages
    },

    setup () {
      let stage = ref('newcomer')
      let score = ref([0])

      let changeStage = newStage => {
        switch (newStage) {
          case 'newcomer':
            stage.value = 'newcomer'
            score.value = [0]
            break
          case 'explorer':
            stage.value = 'explorer'
            score.value = [31]
            break
          case 'challenger':
            stage.value = 'challenger'
            score.value = [46]
            break
          case 'practitioner':
            stage.value = 'practitioner'
            score.value = [61]
            break
          case 'champion':
            stage.value = 'champion'
            score.value = [76]
            break
        }
      }

      return {
        stage,
        score,
        changeStage
      }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .zui {
    font-weight: map.get(variables.$font-weights, 'bold');
    font-size: map.get(variables.$font-sizes, 'lg');
    line-height: 1.5;
  }

  .gku {
    margin: 24px 0;
    line-height: 1.5;
  }

  .gyn {
    margin: 24px auto;
    max-width: 600px;
  }

  .osh {
    font-weight: map.get(variables.$font-weights, 'bold');
    line-height: 1.5;
  }

  .skc {
    margin: 8px 0 0;
    line-height: 1.5;
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .gku {
      margin: 32px 0;
    }

    .gyn {
      margin: 32px auto;
    }
  }
</style>
