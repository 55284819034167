<template>
  <div class='aptitude-types'>
    <div class='zui'>4 Types of Innovation Aptitude</div>
    <p class='gku'>Understand key driving forces behind the current progress of your corporate innovation journey and give the insights of what actions can be taken to move your organization forward the next stage.</p>
    <click-hint-component
      top='25%'
      left='48%'
      name='aptitude-types'>
      <aptitude-types-component class='gyn'
        mode='widget'
        :data='score'
        @select='changeType'/>
    </click-hint-component>
    <template v-if='type === "people"'>
      <div class='osh'>II. People - driven</div>
      <p class='sul'>Your organization has a strong people focus. Not only the management and the employees in the organization have a strong innovation culture, but also have the skill sets to implement and generate tangible results from innovation. However, you still haven't successfully adopted innovative tools and utilized data as well as built a strong technology ecosystem yet.</p>
      <p class='sul'>We would recommend focusing on building, improving, and adopting more technologies and tools as well as invest in data infrastructure to generate additional value from the company's available data.</p>
    </template>
    <template v-if='type === "people-technology"'>
      <div class='osh'>I. Both People and Technology - driven</div>
      <p class='sul'>Your organization is strong at both people and technology. On the people side, the management and the employees have a strong mindset and skill set toward innovation. The innovation team can consistently generate meaningful business ideas and turn them into successful and profitable businesses with full support from the management and the other teams. On the technology side, the company has also adopted various top-tier tools and technologies as well as consistently engage with the leading startups.</p>
      <p class='sul'>We would recommend maintaining the speed and the commitment to corporate innovation to stay ahead of your competitors.</p>
    </template>
    <template v-if='type === "not-people-technology"'>
      <div class='osh'>III. Neither People nor Technology - driven</div>
      <p class='sul'>Your organization has neither people nor technology focus yet. On the people side, there is still a major lack of innovation mindset and skill set among the management and the employees. On the technology side, the company's tools and technology haven't successfully generated tangible business outcomes.</p>
      <p class='sul'>We would recommend involving external innovation experts to provide guidance as well as focus on the mindset transformation of the management and the relevant employees.</p>
    </template>
    <template v-if='type === "technology"'>
      <div class='osh'>IV. Technology - driven</div>
      <p class='sul'>Your organization has a strong technology focus. You are keen to adopt and implement new tools and technologies. You also have a strong data infrastructure and are able to generate value from data. Additionally, you also have built and consistently engaged with your startup ecosystem. However, on the people side, there is still a major lack of innovation mindset and skill set among the management and the employees.</p>
      <p class='sul'>We would recommend focusing on the mindset transformation of the management and the relevant employees as well as provide necessary innovation training for the relevant employees.</p>
    </template>
  </div>
</template>

<script>
  import { ref } from 'vue'
  import AptitudeTypes from '@/components/aptitude-types/aptitude-types.vue'
  import ClickHint from '@/components/click-hint/click-hint.vue'

  export default {
    components: {
      'aptitude-types-component': AptitudeTypes,
      'click-hint-component': ClickHint
    },

    setup () {
      let type = ref('people')
      let score = ref([0, 23])

      let changeType = newType => {
        switch (newType) {
          case 'people':
            type.value = 'people'
            score.value = [0, 23]
            break
          case 'people-technology':
            type.value = 'people-technology'
            score.value = [23, 23]
            break
          case 'not-people-technology':
            type.value = 'not-people-technology'
            score.value = [0, 0]
            break
          case 'technology':
            type.value = 'technology'
            score.value = [23, 0]
            break
        }
      }

      return {
        type,
        score,
        changeType
      }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .zui {
    font-weight: map.get(variables.$font-weights, 'bold');
    font-size: map.get(variables.$font-sizes, 'lg');
    line-height: 1.5;
  }

  .gku {
    margin: 24px 0;
    line-height: 1.5;
  }

  .gyn {
    margin: 24px auto;
    max-width: 600px;
  }

  .osh {
    font-weight: map.get(variables.$font-weights, 'bold');
    line-height: 1.5;
  }

  .sul {
    margin: 16px 0;
    line-height: 1.5;
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .gku {
      margin: 32px 0;
    }

    .gyn {
      margin: 32px auto;
    }
  }
</style>
